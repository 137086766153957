/**
 * Timetastic
 * Features
 */
import React, { useEffect } from "react"
import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SignupButton from "src/components/signupButton"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Timetastic features"
const description =
    "Timetastic features"

// JSX
const Default = () => {
    // Animate any elements with a class of 'is-unanimated'
    useEffect(() => {
        animate()
    }, [])

    return (
        <>
            <SEO title={title} useTitleTemplate={false} description={description} />
            <Layout>
                <Header />
        <FooterCta />
                <main className="c-features-main">
                    <section className="c-section">
                        <div className="u-inner">
                            <div className="is-unanimated u-text-centre-desktop">
                                <div className="c-features-intro__description">
                                    <h1 className="h h2 c-features-intro__title">Features</h1>
                                    <p className="c-features-intro__summary">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt laoreet eros non lacinia. Donec vitae suscipit urna. Vestibulum luctus condimentum sodales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-section">
                        <div className="u-inner u-inner--l">
                            <div className="c-features-index-grid__item">
                                <div className="c-features-grid__media">
                                    <img
                                        loading="eager"
                                        alt="Daily summary in Slack"
                                        className="c-features-grid__image"
                                        src="/images/features/slack-tools/daily-summary.png"
                                    />
                                </div>
                                <div className="c-features-grid__description c-features-index-grid__description">
                                    <h2 className="h h4">
                                        Get notifications in Slack
                                    </h2>
                                    <p>
                                        If you love Slack, then dealing with absence requests in your direct messages is a big win.
                                    </p>
                                    <Link
                                        className="c-features-cross-link__link u-em-link u-em-link--light"
                                        to="/features/slack-tools/"
                                    >
                                        <span>Slack tools</span>
                                        {/* <svg
                                            width="20"
                                            height="14"
                                            viewBox="0 0 20 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                                                fill="currentColor"
                                            />
                                        </svg> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="c-features-index-grid__item">
                                <div className="c-features-grid__media">
                                    <img
                                        loading="lazy"
                                        alt="Absence requests in Slack"
                                        className="c-features-grid__image"
                                        src="/images/features/calendar-connections/outlook-calendar.png"
                                    />
                                </div>
                                <div className="c-features-grid__description c-features-index-grid__description">
                                    <h2 className="h h4">
                                        Sync with your calendars
                                    </h2>
                                    <p>
                                        Connect Timetastic up to your regular calendar and all your absence bookings will automatically feed in.
                                    </p>
                                    <Link
                                        className="c-features-cross-link__link u-em-link u-em-link--light"
                                        to="/features/calendar-connections/"
                                    >
                                        <span>Calendar connections</span>
                                        {/* <svg
                                            width="20"
                                            height="14"
                                            viewBox="0 0 20 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                                                fill="currentColor"
                                            />
                                        </svg> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="c-features-index-grid__item">
                                <div className="c-features-grid__media">
                                    <img
                                        loading="lazy"
                                        alt="Installing Slack"
                                        className="c-features-grid__image"
                                        src="/images/features/email-reports/your-week-ahead.png"
                                    />
                                </div>
                                <div className="c-features-grid__description c-features-index-grid__description">
                                    <h2 className="h h4">
                                        Reports straight to your inbox
                                    </h2>
                                    <p>
                                        Every morning, straight into your inbox you’ll get an email telling you who’s off that day.
                                    </p>
                                    <Link
                                        className="c-features-cross-link__link u-em-link u-em-link--light"
                                        to="/features/email-reports/"
                                    >
                                        <span>Email reports</span>
                                        {/* <svg
                                            width="20"
                                            height="14"
                                            viewBox="0 0 20 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                                                fill="currentColor"
                                            />
                                        </svg> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="c-features-index-grid__item">
                                <div className="c-features-grid__media">
                                    <div class="c-features-grid__media">
                                        <ul class="u-tag-list u-tag-list-feature-index">
                                            <li class="">Annual Leave</li>
                                            <li class="">Sickness</li>
                                            <li class="">Maternity/Paternity</li>
                                            <li class="">Sabbatical</li>
                                            <li class="">Doctors Appointment</li>
                                            <li class="">Seasonal Shutdown</li>
                                            <li class="">Compassionate</li>
                                            <li class="">Away Day</li>
                                            <li class="">Training</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="c-features-grid__description c-features-index-grid__description">
                                    <h2 className="h h4">
                                        Customise your leave types
                                    </h2>
                                    <p>
                                        Book, track, and record all the different types of absence from work.
                                    </p>
                                    <Link
                                        className="c-features-cross-link__link u-em-link u-em-link--light"
                                        to="/features/leave-types/"
                                    >
                                        <span>Leave types</span>
                                        {/* <svg
                                            width="20"
                                            height="14"
                                            viewBox="0 0 20 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                                                fill="currentColor"
                                            />
                                        </svg> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="c-features-index-grid__item">
                                <div className="c-features-grid__media">
                                    <img
                                        loading="lazy"
                                        alt="Installing Slack"
                                        className="c-features-grid__image"
                                        src="/images/features/timetastic-pro/absence-insights.png"
                                    />
                                </div>
                                <div className="c-features-grid__description c-features-index-grid__description">
                                    <h2 className="h h4">
                                        Upgrade security and insights
                                    </h2>
                                    <p>
                                        Say hello to Timetastic Pro - built for growing businesses like yours.
                                    </p>
                                    <Link
                                        className="c-features-cross-link__link u-em-link u-em-link--light"
                                        to="/features/timetastic-pro/"
                                    >
                                        <span>Timetastic Pro</span>
                                        {/* <svg
                                            width="20"
                                            height="14"
                                            viewBox="0 0 20 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                                                fill="currentColor"
                                            />
                                        </svg> */}
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </section>

                    <FreeTrialAlt
                        body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
                        buttonCopy="Start free trial today"
                        title="If you’re ready, give it a try now"
                    />
                </main>
                <Footer />
            </Layout>
        </>
    )
}
export default Default;
